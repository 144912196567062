<template>
  <v-data-table :search="search" class="align-center justify-center fill-height" v-if="orders" :headers="this.headers"
                :items="this.orders"
                :items-per-page="20">
    <template v-slot:item.day="{item}">
      <strong class="primary--text mx-auto border-white">
        {{ item.day }}
      </strong>
    </template>

    <template v-slot:item.date="{item}">
      <strong class="mx-auto">
        {{ new Date(parseInt(item.date) * 1000).toDateString() }}
      </strong>
    </template>

    <template class="border" v-slot:item.items="{item}">
      <v-list class="transparent">
        <v-list-item
            v-for="(product , i) in item.items"
            :key="i"
            :class="item.items.length> 1 && item.items.indexOf(product) !== (item.items.length -1)? 'border-bottom' : ''"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ product.name }}
              <h6><strong>{{ product.price | currency }}</strong></h6>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="font-weight-bolder primary--text">
            x {{ product.quantity }}
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'OrdersDataTable',
  ssr: false,
  props: {
    orders: Array,
    search: String
  },
  data: () => {
    return {
      headers: [
        {
          text: '',
          value: 'day',
          sortable: false
        },
        {
          text: '',
          value: 'date',
          sortable: false
        },
        {
          text: '',
          value: 'items',
          sortable: false
        }
      ]
    }
  },
  computed: {},
  created() {
    this.$forceUpdate();
  }
}
</script>

<style scoped>

</style>
