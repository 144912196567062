<template>
  <v-container fluid>
    <v-card class="" elevation="0">
      <v-card-text class="pa-3">
        <v-row align="center" class="" no-gutters>
          <v-col class="pl-0 text-left" cols="12" md="6" sm="12">
            <v-btn class="pl-0" icon @click="setCurrentWeek(-1)">
              <v-icon
                  color="primary"
                  x-large
              >mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
                color="primary"
                outlined
                @click="setCurrentWeek(0)  & reloadWeek()"
            >
              {{ $t('generic.lang_today') }}
            </v-btn>
            <v-btn icon @click="setCurrentWeek(1)">
              <v-icon
                  color="primary"
                  x-large
              >mdi-chevron-right
              </v-icon>
            </v-btn>
            <strong class="primary--text">
              {{ this.firstday }} - {{ this.lastday }}
            </strong>
          </v-col>

          <v-col class="text-right" cols="12" md="2" sm="12">
            <b-form-input class="ma-auto" :placeholder="$t('generic.lang_search')" size="md"
                          style="height: 44px !important;"/>
          </v-col>
          <v-col class="border-right pl-0" cols="4" md="2" sm="4">
            <v-btn :loading="downloadingPdfLoader" :disabled="downloadingPdfLoader" @click="downloadPDF" class="mr-2"
                   color="primary" icon>
              <v-icon class="f-size-52" x-large>
                mdi-file-pdf-box
              </v-icon>
            </v-btn>
            <!--            <v-btn color="success" icon>
                          <v-icon class="f-size-52" x-large>
                            mdi-file-excel-box
                          </v-icon>
                        </v-btn>-->
          </v-col>

          <v-col class="text-right" md="2">
            <v-btn color="primary" elevation="0" large link to="/menu">
              {{ $t('generic.lang_newOrder') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pa-0">
        <orders-data-table v-bind:search="search" :orders="this.orders" class="mt-0"/>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>


import OrdersDataTable from '@/components/dashboard/orders/overview/OrdersDataTable'
import ENDPOINTS from "@/plugins/axios/endpoints";
import FileSaver from 'file-saver'

export default {
  name: 'OrdersView',
  async beforeRouteEnter(to, from, next) {
    /**
     * init dates
     * */
    let curr = new Date()
    curr.setHours(0, 0, 0, 0)

    let first = curr.getDate() - curr.getDay() + 1 // First day is the day of the month - the day of the week
    curr.setDate(first)
    curr = new Date(curr.getTime() + (24 * 60 * 60 * 1000))
    first = curr.getDate() - curr.getDay() + 1

    const last = first + 5;


    let start = Math.round(new Date(curr.setDate(first)).getTime() / 1000),
        end = Math.round(new Date(curr.setDate(last)).getTime() / 1000);

    const orders = await window.axios.get('/canteen/archives', {
      params: {
        start: start,
        end: end
      }
    }).then((res) => res.data.data)
    next(vm => vm.archives = orders);
  },
  components: {
    OrdersDataTable,
  },
  mounted() {
    this.setCurrentWeek()
  },
  methods: {
    downloadPDF() {
      this.downloadingPdfLoader = true;
      this.$axios.get(ENDPOINTS.CANTEEN.ORDER.PDF, {
            responseType: 'arraybuffer',
            params: {
              start: Math.round(new Date(this.firstday).getTime() / 1000),
              finished: Math.round(new Date(this.lastday).getTime() / 1000),
            }

          }
      ).then((res) => {
        //const blobContent = new Blob([res.data], {type: 'pdf'});
        FileSaver.saveAs(new Blob([res.data], {
          type: "pdf"
        }), "lunchBee-archive.pdf");
      }).catch((err) => {
        this.$store.commit('alert/show', {
          message: err.message,
          color: 'error',
          visible: true
        })
      }).finally(() => {
        this.downloadingPdfLoader = false;
      })
    },
    setCurrentWeek(next = 0) {
      if (this.curr === null || next == 0) {
        this.curr = new Date
      }
      let first = this.curr.getDate() - this.curr.getDay() + 1 // First day is the day of the month - the day of the week
      if (next > 0) {
        this.curr.setDate(first)
        this.curr = new Date(this.curr.getTime() + (next * 7 * 24 * 60 * 60 * 1000))
        first = this.curr.getDate() - this.curr.getDay() + 1

      } else if (next < 0) {
        this.curr.setDate(first)
        this.curr = new Date(this.curr.getTime() + (next * 7 * 24 * 60 * 60 * 1000))
        first = this.curr.getDate() - this.curr.getDay() + 1
      }

      let last = first + 5
      this.firstday = new Date(this.curr.setDate(first)).toDateString()
      this.lastday = new Date(this.curr.setDate(last)).toDateString()


      if (next === 1 || next === -1)
        this.reloadWeek()
    },
    async reloadWeek() {
      /**
       * init dates based on current date
       * */
      let curr = new Date(this.curr)
      curr.setHours(0, 0, 0, 0)

      let first = curr.getDate() - curr.getDay() + 1 // First day is the day of the month - the day of the week
      curr.setDate(first)
      curr = new Date(curr.getTime() + (24 * 60 * 60 * 1000))
      first = curr.getDate() - curr.getDay() + 1

      const last = first + 5;


      let start = Math.round(new Date(curr.setDate(first)).getTime() / 1000),
          end = Math.round(new Date(curr.setDate(last)).getTime() / 1000);

      const orders = await window.axios.get('/canteen/archives', {
        params: {
          start: start,
          end: end
        }
      }).then((res) => res.data.data)
      this.archives = orders;
    }
  },
  computed: {
    orders() {
      const oldItems = [];
      if (this.archives) {
        this.archives.forEach((elt) => {
          const dt = new Date(parseInt(elt.date) * 1000)
          elt.day = this.weekDays[dt.getDay() - 1];
          oldItems.push(elt);
        })
      }
      return oldItems
    }
  },
  data: () => {
    return {
      picker: '',
      today: '',
      firstday: '',
      lastday: '',
      curr: null,
      archives: [],
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      search: "",
      downloadingPdfLoader: false
    }
  }
}
</script>

<style scoped>
.f-size-52 {
  font-size: 54px !important;
}
</style>
